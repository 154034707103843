<template src="../templates/modal-form.html" />

<script>
import Modal from '@c/Modal';
import Input from '@f/Input';
import Select from '@f/Select';
import {saveCyborg} from '@api/cyborg';
import Multiselect from '@vueform/multiselect';
import ModalStatus from './modal-status';
import '@vueform/multiselect/themes/default.css';

export default {
    name: 'ModalForm',
    components: {
        Modal,
        Input,
        Select,
        ModalStatus,
        Multiselect
    },
    props: {
        toggleModalHandler: {
            type: Function,
            default: () => {}
        },
        formData: {
            type: Object,
            default: Object.prototype
        }
    },

    emits: ['save'],

    data() {
        return {
            data: this.formData,
            showModalStatus: false,
            message: '',
            visible: true,
        };
    },

    methods: {
        async submitForm() {
            this.message = await saveCyborg(this.formData);
            this.showModalStatus = true;
            this.visible = false;
            this.$emit('save');
            setTimeout(() => {
                this.showModalStatus = false;
                this.toggleModalHandler();
            }, 2000);

            return true;
        },
    }
};
</script>
