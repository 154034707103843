<template>
    <teleport to="body">
        <Modal v-show="visible"
               :close-button="true"
               :buttons="[
                    {
                        label: !data.id ? 'Добавить' : 'Сохранить',
                        event: 'submitForm',
                        class: 'btn-green h36 inline'
                    }
                ]"
               :template-buttons="{class: 'modal-section mx-0 modal-buttons-p'}"
               @onClose="toggleModalHandler"
               @submitForm="submitForm">

            <template #header>
                <h2>{{ !data.id ? 'Добавить инструмент' : 'Редактировать инструмент' }}</h2>
            </template>

            <template #body>
                <form id="login-form" action="#" class="ph-3 flex-direction-column">
                    <div class="row-col">
                        <div class="col">
                            <Input
                                v-model="data.name"
                                label="Название инструмента:"
                                :attributes="{name:'name',type:'text',placeholder:'Введите название инструмента'}"
                            ></Input>
                        </div>
                    </div>
                    <div class="row-col mt-1">
                        <div class="col">
                            <Select
                                v-model="data.parent"
                                :options="data.parents"
                                label="Родительский инструмент:"
                            ></Select>
                        </div>
                    </div>
                    <div class="row-col mt-1">
                        <div class="col">
                            <label class="h6">Для кого инструмент:</label>
                            <Multiselect
                                v-model="data.userType"
                                :options="data.usersTypes"
                                label="Для кого инструмент:"
                                mode="tags"
                            ></Multiselect>
                        </div>
                    </div>
                    <div class="row-col mt-1">
                        <div class="col">
                            <Select
                                v-model="data.status"
                                :options="data.statuses"
                                label="Статус инструмента:"
                            ></Select>
                        </div>
                    </div>
                    <div class="row-col mt-1">
                        <div class="col">
                            <Input
                                v-model="data.order"
                                label="Глубина сортировки:"
                                :attributes="{name:'order',type:'text',placeholder:'Введите значение для сортировки'}"
                            ></Input>
                        </div>
                    </div>
                </form>
            </template>
        </Modal>
    </teleport>

    <ModalStatus v-if="showModalStatus" :message="message"></ModalStatus>
</template>
