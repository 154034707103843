<div :id="id" :ref="ref" class="cyborg__main-card">
    <div v-if="parent" class="cyborg__main-title">
        <a :href="`/cyborg/${parent.id}`" :style="`color: ${children.length === 0 ? statusColors[parent.status] : 'inherit'}`">{{ parent.name }}</a>
        <span class="btn-inner btn-edit ml-1" @click="toggleModalHandler(parent.id)" v-if="permissions">✎</span>
    </div>
    <div v-if="children.length > 0" class="cyborg__main-list">
        <ul>
            <li v-for="(child, index) in children" :key="index">
                <a :href="`/cyborg/${child.id}`" :style="`color: ${statusColors[child.status]}`">• {{child.name}}</a>
                <span class="btn-inner btn-edit ml-1" @click="toggleModalHandler(child.id)">✎</span>
            </li>
        </ul>
    </div>
</div>
