<template src="../templates/card-instrument.html"></template>

<script>
export default {
    name: 'CardInstrument',
    props: {
        id: {
            type: String,
            default: null
        },
        ref: {
            type: String,
            default: null
        },
        parent: {
            type: Object,
            default: Object.prototype
        },
        children: {
            type: Array,
            default: Array.prototype
        },
        toggleModalHandler: {
            type: Function,
            default: () => {}
        },
        permissions: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            statusColors: {
                'В планах': 'rgba(55, 53, 47, 0.6)',
                'В работе': '#1A66FF',
                Реализовано: '#00876B',
            }
        };
    },

    methods: {
        editForm(id) {
            this.toggleModalHandler(id);
        }
    }
};
</script>
