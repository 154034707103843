<template src="../templates/block-card-instrument.html"></template>

<script>
import CardInstrument from './card-instrument';

export default {
    name: 'BlockCardInstrument',
    components: {
        CardInstrument
    },
    props: {
        data: {
            type: Array,
            default: Array.prototype
        },
        type: {
            type: String,
            default: ''
        },
        toggleModalHandler: {
            type: Function,
            default: () => {}
        },
        permissions: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        parents() {
            return this.data.filter(instrument => instrument.parent_page_id === null);
        }
    }
};
</script>
