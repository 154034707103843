<div :id="`cyborg-${type}`" :class="['col-4', type, type === 'left' ? 'pr-0' : 'pl-0', ' d-flex', 'flex-direction-column']">
    <template v-for="(item, i) in parents">
        <template v-if="type === 'left' ? ((Object.keys(parents).length / 2) > i) : !((Object.keys(parents).length / 2) > i)">
            <CardInstrument
                :id="`card-${i}`"
                :ref="`card-${i}`"
                :key="i"
                :parent="item"
                :children="data.filter(instrument => instrument.parent_page_id === item.id)"
                :toggleModalHandler="toggleModalHandler"
                :permissions="permissions">
            </CardInstrument>
        </template>
    </template>
</div>
