<template src="./templates/cyborg.html"></template>

<script>
import './styles/cyborg.scss';
import {mapGetters} from 'vuex';
import Modal from '@c/Modal';
import Loader from '@c/Loader';
import Button from '@f/Button';
import Input from '@f/Input';
import Select from '@f/Select';
import {
    getCyborgPermission, getCyborg, getCyborgInstruments, getCyborgStatuses, getCyborgForm, getCyborgUsersTypes, getCyborgInstrumentRelation
} from '@api/cyborg';
import ModalForm from './components/modal-form';
import BlockCardInstrument from './components/block-card-instrument';

export default {
    name: 'Cyborg',
    components: {
        Modal,
        Loader,
        Button,
        Input,
        Select,
        ModalForm,
        BlockCardInstrument,
    },

    data() {
        return {
            checkPermission: false,
            instrumentsAll: [],
            roles: [],
            activeRole: 1,
            statuses: [],
            changeStatus: '',
            preloaderCyborgName: 'cyborg_generate',
            showModalForm: false,
            formData: [],
            usersTypes: [],
            instrumentRelation: {},
        };
    },

    computed: {
        ...mapGetters({
            preloaders: 'preloaders',
        }),
        instruments() {
            return this.$store.state.Cyborg.instruments;
        },
        preloaderCalculate() {
            return this.preloaders[this.preloaderCyborgName];
        },
    },

    async created() {
        await this.init();
    },

    methods: {
        async init() {
            this.checkPermission = await getCyborgPermission();
            this.instrumentsAll = await getCyborg();
            this.roles = await getCyborgInstruments();
            this.instrumentRelation = await getCyborgInstrumentRelation();
            this.statuses = await getCyborgStatuses();
            this.usersTypes = await getCyborgUsersTypes();

            this.instrumentsAll.sort((a, b) => a.order - b.order);
            this.$store.dispatch('initInstruments', this.instrumentsAll);

            this.changeInstrument(this.activeRole);
        },

        changeInstrument(id) {
            // eslint-disable-next-line no-param-reassign
            if (typeof id === 'object') id = this.activeRole;

            this.activeRole = id;
            const instrumentIds = Object.values(this.instrumentRelation).length > 0 && this.instrumentRelation[id] ? Object.values(this.instrumentRelation[id]) : [];
            let localInstruments = this.instrumentsAll;
            // Фильтр по инструментам
            localInstruments = localInstruments.filter(instrument => instrumentIds.includes(instrument.id));
            // Фильтр по статусам
            if (this.changeStatus.length > 0) {
                localInstruments = localInstruments.filter(instrument => instrument.status === this.changeStatus);
            }

            if (localInstruments.length > 0) {
                localInstruments.forEach(el => {
                    if (el.parent_page_id !== null) {
                        const getParent = this.instrumentsAll.filter(instrument => instrument.id === el.parent_page_id);
                        if (getParent.length > 0) {
                            const checkParent = localInstruments.filter(instrument => instrument.id === getParent[0].id);
                            if (!checkParent || checkParent.length === 0) {
                                localInstruments.push(getParent[0]);
                            }
                        }
                    }
                });
            }

            this.$store.dispatch('initInstruments', localInstruments);
        },

        async toggleModalHandler(id) {
            this.formData = await getCyborgForm({id});
            this.formData.parents = this.instrumentsAll.filter(el => el.parent_page_id === null).map(value => value.name);
            this.formData.statuses = this.statuses;
            this.formData.usersTypes = this.usersTypes;
            this.showModalForm = !this.showModalForm;
        },

        async saveForm() {
            this.$store.dispatch('initInstruments', null);
            await this.init();
        },
    },
};
</script>
